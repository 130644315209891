exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktivieren-js": () => import("./../../../src/pages/aktivieren.js" /* webpackChunkName: "component---src-pages-aktivieren-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-passwort-vergessen-js": () => import("./../../../src/pages/passwort-vergessen.js" /* webpackChunkName: "component---src-pages-passwort-vergessen-js" */),
  "component---src-pages-passwort-zuruecksetzen-js": () => import("./../../../src/pages/passwort-zuruecksetzen.js" /* webpackChunkName: "component---src-pages-passwort-zuruecksetzen-js" */),
  "component---src-pages-produktinfo-audios-videos-js": () => import("./../../../src/pages/produktinfo/audios-videos.js" /* webpackChunkName: "component---src-pages-produktinfo-audios-videos-js" */),
  "component---src-pages-produktinfo-autoren-js": () => import("./../../../src/pages/produktinfo/autoren.js" /* webpackChunkName: "component---src-pages-produktinfo-autoren-js" */),
  "component---src-pages-produktinfo-bilddatenbank-js": () => import("./../../../src/pages/produktinfo/bilddatenbank.js" /* webpackChunkName: "component---src-pages-produktinfo-bilddatenbank-js" */),
  "component---src-pages-produktinfo-faq-js": () => import("./../../../src/pages/produktinfo/faq.js" /* webpackChunkName: "component---src-pages-produktinfo-faq-js" */),
  "component---src-pages-produktinfo-kommentare-js": () => import("./../../../src/pages/produktinfo/kommentare.js" /* webpackChunkName: "component---src-pages-produktinfo-kommentare-js" */),
  "component---src-pages-produktinfo-lernpakete-js": () => import("./../../../src/pages/produktinfo/lernpakete.js" /* webpackChunkName: "component---src-pages-produktinfo-lernpakete-js" */),
  "component---src-pages-produktinfo-muendlich-js": () => import("./../../../src/pages/produktinfo/muendlich.js" /* webpackChunkName: "component---src-pages-produktinfo-muendlich-js" */),
  "component---src-pages-produktinfo-notizen-js": () => import("./../../../src/pages/produktinfo/notizen.js" /* webpackChunkName: "component---src-pages-produktinfo-notizen-js" */),
  "component---src-pages-produktinfo-nutzungsbedingungen-js": () => import("./../../../src/pages/produktinfo/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-produktinfo-nutzungsbedingungen-js" */),
  "component---src-pages-produktinfo-pruefungsarchiv-js": () => import("./../../../src/pages/produktinfo/pruefungsarchiv.js" /* webpackChunkName: "component---src-pages-produktinfo-pruefungsarchiv-js" */),
  "component---src-pages-produktinfo-pruefungsfragen-js": () => import("./../../../src/pages/produktinfo/pruefungsfragen.js" /* webpackChunkName: "component---src-pages-produktinfo-pruefungsfragen-js" */),
  "component---src-pages-produktinfo-sitzungsoptionen-js": () => import("./../../../src/pages/produktinfo/sitzungsoptionen.js" /* webpackChunkName: "component---src-pages-produktinfo-sitzungsoptionen-js" */),
  "component---src-pages-produktinfo-spicker-js": () => import("./../../../src/pages/produktinfo/spicker.js" /* webpackChunkName: "component---src-pages-produktinfo-spicker-js" */),
  "component---src-pages-produktinfo-statistiken-js": () => import("./../../../src/pages/produktinfo/statistiken.js" /* webpackChunkName: "component---src-pages-produktinfo-statistiken-js" */),
  "component---src-pages-produktinfo-top-news-js": () => import("./../../../src/pages/produktinfo/top-news.js" /* webpackChunkName: "component---src-pages-produktinfo-top-news-js" */),
  "component---src-pages-produktinfo-zugang-preise-js": () => import("./../../../src/pages/produktinfo/zugang-preise.js" /* webpackChunkName: "component---src-pages-produktinfo-zugang-preise-js" */),
  "component---src-pages-registrieren-js": () => import("./../../../src/pages/registrieren.js" /* webpackChunkName: "component---src-pages-registrieren-js" */)
}

