import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createGlobalStyle } from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ContentWrapper, GlobalStyles } from "@thieme/startpage-components/src/components";
import { StartpageProvider } from "@thieme/startpage-components/src/context";
import config from "../config";

const GlobalStyle = createGlobalStyle`
  ${GlobalStyles}
`;

const Layout = ({ children }) => {
  const [statusCode, setStatusCode] = useState(0);

  const fetchStatus = () => {
    fetch(config.STATUS_DASHBOARD_URL)
      .then((res) => res.json())
      .then((res) => {
        let code = res.data.code;
        setStatusCode(code);
      })
      .catch((err) => {
        console.error({ err });
        setStatusCode(0);
      });
  };

  useEffect(() => {
    if (config.STATUS_DASHBOARD_IS_ACTIVE === true) {
      fetchStatus();

      setInterval(
        () => {
          fetchStatus();
        },
        1000 * 60 * 5
      );
    }
  }, [config.STATUS_DASHBOARD_IS_ACTIVE]);

  return (
    <StartpageProvider config={config}>
      <GlobalStyle />
      <Header statusCode={statusCode} />
      <ContentWrapper>{children}</ContentWrapper>
      <Footer statusCode={statusCode} />
    </StartpageProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
