import React from "react";
import {
  FooterLine,
  ThiemeCopyRight,
  FooterLinks,
  FooterLink,
  FooterLinkIntern,
  VideoText,
  InfoLine,
  SocialIcons,
  StatusIcon,
} from "@thieme/startpage-components/src/components";
import { StartpageConsumer } from "@thieme/startpage-components/src/context";
import config from "../config";

const Footer = ({ statusCode }) => (
  <StartpageConsumer>
    {(store) => (
      <React.Fragment>
        <FooterLine id="footer">
          <ThiemeCopyRight />
          <FooterLinks>
            <FooterLinkIntern to="/produktinfo/nutzungsbedingungen" title="Nutzungsbedingungen">
              Nutzungsbedingungen
            </FooterLinkIntern>{" "}
            |{" "}
            <FooterLink
              href="https://www.thieme.com/de-de/datenschutzerklaerung/georg-thieme-verlag-gmbh"
              target="_blank"
              rel="noopener noreferrer"
              title="Datenschutz"
            >
              Datenschutz
            </FooterLink>{" "}
            |{" "}
            <FooterLink
              href="https://www.thieme.de/de/thieme-gruppe/Impressum-Thieme-DE.htm"
              target="_blank"
              rel="noopener noreferrer"
              title="Impressum"
            >
              Impressum
            </FooterLink>
            {statusCode > 0 && (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                Status{" "}
                <StatusIcon
                  product={config.PRODUCT_TITLE_SHORT}
                  statusCode={statusCode}
                  publicDashboardUrl={config.STATUS_DASHBOARD_PUBLIC_URL}
                  design={"border"}
                  main
                />
              </div>
            )}
          </FooterLinks>
          <VideoText>Video Startseite: cinematicstock/stock.adobe.com</VideoText>
          <SocialIcons />
        </FooterLine>
        <InfoLine remoteIpAddress={store.clientInfo.userIp} />
      </React.Fragment>
    )}
  </StartpageConsumer>
);

export default Footer;
