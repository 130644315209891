import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faFlag } from "@fortawesome/free-solid-svg-icons/faFlag";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";

import {
  CiLine1,
  CiLine2,
  CiLine2Left,
  CiLine2Right,
} from "@thieme/startpage-components/src/components";
import { LogoHaug } from "@thieme/startpage-components/src/assets";
import { LogoNonMedHealer } from "@thieme/startpage-components/src/assets";

import {
  Menu,
  HeaderStyled,
  MenuWrapper,
  MenuRight,
  LoginButton,
  RegisterButton,
  LogoLink,
  StatusIcon,
} from "@thieme/startpage-components/src/components";
import config from "../config";

const Header = ({ statusCode }) => (
  <HeaderStyled>
    <CiLine1 />
    <CiLine2 haug>
      <CiLine2Left>
        <LogoLink to="/">
          <LogoHaug />
          <LogoNonMedHealer />
        </LogoLink>
      </CiLine2Left>
      <CiLine2Right />
    </CiLine2>
    <MenuWrapper>
      <MenuRight>
        {statusCode > 1 && (
          <StatusIcon
            product={config.PRODUCT_TITLE_SHORT}
            statusCode={statusCode}
            publicDashboardUrl={config.STATUS_DASHBOARD_PUBLIC_URL}
            design={"border"}
          />
        )}
        <LoginButton href="/kreuzen">Login</LoginButton>
        <RegisterButton to="/registrieren">registrieren</RegisterButton>
      </MenuRight>
      <Menu>
        <Menu.MainLink to="/">Home</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/zugang-preise">Zugang/Preise</Menu.MainLink>
        <Menu.Block title="Inhalt">
          <Menu.SubLink to="/produktinfo/pruefungsfragen">
            <FontAwesomeIcon icon={faQuestion} color="#013476" />
            Prüfungsfragen
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/kommentare">
            <FontAwesomeIcon icon={faComment} color="#013476" />
            Kommentare
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/spicker">
            <FontAwesomeIcon icon={faEye} color="#013476" />
            Prüfungsrelevante Spicker
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/audios-videos">
            <FontAwesomeIcon icon={faPlayCircle} color="#013476" />
            Audios und Videos
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/bilddatenbank">
            <FontAwesomeIcon icon={faImage} color="#013476" />
            Bilddatenbank
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/lernpakete">
            <FontAwesomeIcon icon={faFlag} color="#013476" />
            Lernpakete
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/muendlich">
            <FontAwesomeIcon icon={faComments} color="#013476" />
            Mündliche Prüfung
          </Menu.SubLink>
        </Menu.Block>
        <Menu.Block title="Features">
          <Menu.SubLink to="/produktinfo/top-news">
            <FontAwesomeIcon icon={faStar} color="#013476" />
            Top-News
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/sitzungsoptionen">
            <FontAwesomeIcon icon={faCog} color="#013476" />
            Sitzungsoptionen
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/statistiken">
            <FontAwesomeIcon icon={faChartLine} color="#013476" />
            Statistiken
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/notizen">
            <FontAwesomeIcon icon={faEdit} color="#013476" />
            Notizen/Markierungen
          </Menu.SubLink>
        </Menu.Block>
        <Menu.MainLink to="/produktinfo/autoren">Autoren</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/faq">FAQ</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/pruefungsarchiv">Prüfungsarchiv</Menu.MainLink>
      </Menu>
    </MenuWrapper>
  </HeaderStyled>
);

export default Header;
